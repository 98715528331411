import React from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'

import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Container } from '../components/Container'
import { Map } from '../components/Map'

import { Person } from '../interfaces'

interface ExtendedPerson extends Person {
  picture: {
    fixed: {
      src: string
    }
  }
}

interface Props {
  data: {
    people: {
      nodes: Array<ExtendedPerson>
    }
  }
}

function ContactPage(props: Props) {
  return (
    <Layout spaceTop={true}>
      <SEO title="Kontakt" />

      <Container>
        <h1 className="font-serif text-3xl text-gray-700 pb-3">Kontakt</h1>
        <hr />

        <section className="md:flex pt-8">
          {props.data.people.nodes.map((person, idx) => (
            <div
              key={person.id}
              className={classNames('sm:flex sm:justify-center', {
                'mt-8 sm:mt-12 md:mt-0': idx !== 0,
                'md:mr-12 lg:mr-20': idx === 0,
              })}
            >
              <img
                src={person.picture.fixed.src}
                className="rounded-full mx-auto sm:mx-0 object-cover"
                style={{ height: 200, width: 200, objectPosition: 'center 10%' }}
                alt={person.name}
              />
              <div className="text-center mt-3 sm:text-left sm:pl-6">
                <h3 className="font-serif text-xl">{person.name}</h3>
                <ul className="my-2">
                  {person.phoneNumbers.map((number, idx) => (
                    <li key={idx} className="text-sm">
                      {number}
                    </li>
                  ))}
                </ul>
                <a href={`mailto:${person.email}`} className="text-blue-600">
                  {person.email}
                </a>
              </div>
            </div>
          ))}
        </section>

        <section className="pt-12 md:pt-16 lg:pt-20">
          <div className="text-center mb-4">
            <h2 className="mb-3 text-lg font-bold text-gray-800">Advokatfirman Sievo AB</h2>
            <p>Stockholmvägen 18</p>
            <p>181 50 Lidingö</p>
          </div>
          <Map />
        </section>
      </Container>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  {
    people: allDatoCmsPerson(filter: { slug: { eq: "roine-sievo" } }) {
      nodes {
        id
        name
        title
        phoneNumbers
        email
        picture {
          fixed(width: 400) {
            src
          }
        }
      }
    }
  }
`
