import React from 'react'

function Map() {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13830.302240005381!2d18.10663281844112!3d59.35886962338608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9d2ad9212b75%3A0x8f897fb37f85b693!2sAdvokatfirman+Sievo+AB!5e0!3m2!1sen!2sse!4v1562156057621!5m2!1sen!2sse"
      width="100%"
      height="450"
      frameBorder="0"
      style={{ border: 0 }}
      allowFullScreen
    ></iframe>
  )
}

export { Map }
